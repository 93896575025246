import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ScreenContext } from 'react-components'
import { ThemeContext } from 'styled-components'

import { AllInputsDisplayBar, SearchbarContainer, SearchbarContent, SearchButton } from './styles'
import { SearchRegionNodeTransport } from '../../../../transports/search-result-listing-page-transport'
import AllInputsModal from './all-inputs-modal'
import { useTranslation } from '../../../../hooks/locale'
import { getDatePickerLabel, getGuestPickerLabel, getLocationPickerLabel } from './utils'
import { Location } from '../types'
import { SearchbarHistoryItem } from '../../../../redux/searchbar/types'
import { useDatePickerType } from '../../../common/date-picker/hooks'
import SearchbarGuestPicker from './guest-picker'
import { useOpen } from './hooks'
import SearchbarDatePicker from './date-picker'
import RegionsAutocompleteSuggestionPicker from './regions-autocomplete-suggestion-picker'
import RegionsAutocompleteSuggestionTransport from '../../../../transports/regions-autocomplete-suggestion-transport'
import SearchIcon from '../../../../assets/icons/userbar-icons/search.svg'
import AnalyticsService from '../../../../services/analytics-service'
import { commonPageEvents } from '../../../../services/analytics-service/events'
import { useDateFormatter } from '../../../../hooks/date'

interface Props {
    searchRegionNode: SearchRegionNodeTransport
    onSearch: (params: {
        location: Location
        arrivalDate: Date | null
        departureDate: Date | null
        adultsCount: number
        childrenCount: number
    }) => void
    maxWidth?: string
    isAllInputOpen?: boolean
    toggleAllInputModal?: () => void
}

const Searchbar: FC<Props> = ({ searchRegionNode, onSearch, maxWidth, isAllInputOpen, toggleAllInputModal }) => {
    const {
        search: initialSearch,
        arrival: initialArrival,
        departure: initialDeparture,
        adults: initialAdultsCount,
        children: initialChildrenCount,
    } = searchRegionNode

    // Maintain initial states as a copy in case a reset to initial states is required.
    const initialLocation: Location = useMemo(() => ({ regionId: null, search: initialSearch ?? '' }), [initialSearch])
    const initialArrivalDate = useMemo(() => (initialArrival ? new Date(initialArrival) : null), [initialArrival])
    const initialDepartureDate = useMemo(
        () => (initialDeparture ? new Date(initialDeparture) : null),
        [initialDeparture],
    )

    // Input state for the searchbar.
    const [location, setLocation] = useState(initialLocation)
    const [arrivalDate, setArrivalDate] = useState(initialArrivalDate)
    const [departureDate, setDepartureDate] = useState(initialDepartureDate)
    const [adultsCount, setAdultsCount] = useState(initialAdultsCount)
    const [childrenCount, setChildrenCount] = useState(initialChildrenCount)
    const _totalGuestsCount = adultsCount + childrenCount

    useEffect(() => {
        if (isAllInputOpen) {
            setLocation(initialLocation)
            setArrivalDate(initialArrivalDate)
            setDepartureDate(initialDeparture ? new Date(initialDeparture) : null)
            setAdultsCount(initialAdultsCount ?? 1)
            setChildrenCount(initialChildrenCount ?? 0)
        }
    }, [
        isAllInputOpen,
        initialLocation,
        initialArrivalDate,
        initialDeparture,
        initialAdultsCount,
        initialChildrenCount,
    ])

    // Individual modal/picker states for the searchbar.
    const {
        isOpen: isAllInputsModalOpen,
        onOpen: onAllInputsModalOpen,
        onClose: onAllInputsModalClose,
    } = useOpen(false)

    const {
        isOpen: isLocationPickerOpen,
        onOpen: onLocationPickerOpen,
        onClose: onLocationPickerClose,
    } = useOpen(false)

    const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useOpen(false)
    const { isOpen: isGuestPickerOpen, onOpen: onGuestPickerOpen, onClose: onGuestPickerClose } = useOpen(false)

    const [locationPickerError, setLocationPickerError] = useState('')

    const _handleLocationPickerOpen = useCallback(() => {
        setLocationPickerError('')
        onLocationPickerOpen()
    }, [onLocationPickerOpen])

    const _handleGuestPickerOpen = useCallback(() => {
        if (!isGuestPickerOpen) {
            AnalyticsService.trackEvent({
                event: commonPageEvents.searchBarSection.CLICK_PERS_FILTER,
                eventLabel: 'searchbar',
            })
        }
        onGuestPickerOpen()
    }, [isGuestPickerOpen, onGuestPickerOpen])

    const _handleGuestPickerClose = useCallback(() => {
        if (isGuestPickerOpen) {
            // Hack to make sure that we get the updated count when guest-picker is closed, as it is possible that the
            // `submit` updates have not been applied yet by React if the tracking does not use this hack.
            setAdultsCount(adultsCount => {
                setChildrenCount(childrenCount => {
                    AnalyticsService.trackEvent({
                        event: commonPageEvents.searchBarSection.SENT_PERS_FILTER,
                        eventLabel: 'searchbar',
                        eventValue: `adults=${adultsCount} kids=${childrenCount}`,
                    })
                    return childrenCount
                })
                return adultsCount
            })
        }
        onGuestPickerClose()
    }, [isGuestPickerOpen, onGuestPickerClose])

    const { isMobile, isTablet } = useContext(ScreenContext)
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)

    const _handleAllInputsModalClose = useCallback(() => {
        setLocationPickerError('')

        // Resetting state on outside or cross click.
        setLocation(initialLocation)
        setArrivalDate(initialArrivalDate)
        setDepartureDate(initialDepartureDate)
        setAdultsCount(initialAdultsCount)
        setChildrenCount(initialChildrenCount)

        !!toggleAllInputModal ? toggleAllInputModal() : onAllInputsModalClose()
    }, [
        initialAdultsCount,
        initialArrivalDate,
        initialChildrenCount,
        initialDepartureDate,
        initialLocation,
        onAllInputsModalClose,
        toggleAllInputModal,
    ])

    const _handleLocationInputChange = useCallback((input: string) => {
        // Optimisation for not changing the state when input is the same. Prevent unnecessary re-renders.
        setLocation(location => (location.search !== input ? { regionId: null, search: input } : location))
    }, [])

    const _handleLocationInputSelect = useCallback(
        (input: string) => {
            _handleLocationInputChange(input)
            onLocationPickerClose()
            if (!arrivalDate || !departureDate) {
                onDatePickerOpen()
            }
        },
        [_handleLocationInputChange, arrivalDate, departureDate, onDatePickerOpen, onLocationPickerClose],
    )

    const _handleLocationItemSelect = useCallback(
        (item: RegionsAutocompleteSuggestionTransport) => {
            setLocation({ search: item.title, regionId: item.regionId })
            if (!arrivalDate || !departureDate) {
                onDatePickerOpen()
            }
        },
        [arrivalDate, departureDate, onDatePickerOpen],
    )

    const _handleHistoryItemSelect = useCallback((historyItem: SearchbarHistoryItem) => {
        const { search, arrival, departure, adults, children } = historyItem
        setLocation({ regionId: null, search })
        setArrivalDate(arrival)
        setDepartureDate(departure)
        setAdultsCount(adults)
        setChildrenCount(children)
    }, [])

    const { format } = useDateFormatter()

    const _trackArrivalDateChange = useCallback(
        (startDate: Date | null, endDate?: Date | null) => {
            if (startDate) {
                AnalyticsService.trackEvent({
                    event: 'datepicker_select',
                    eventScope: 'dates[form_date_from]',
                    eventValue: format(startDate, 'dd.MM.yyyy'),
                })

                AnalyticsService.trackEvent({
                    action: 'searchbox_add_arrival',
                    category: 'searchbox',
                    label: format(startDate, 'yyyy-MM-dd'),
                })

                if (endDate) {
                    AnalyticsService.trackEvent({ eventAction: 'entered_arrival_and_departure', eventLabel: 'tbd' })
                }
            }
        },
        [format],
    )

    const _trackDepartureDateChange = useCallback(
        (startDate: Date | null, endDate: Date | null) => {
            if (endDate) {
                AnalyticsService.trackEvent({
                    event: 'datepicker_select',
                    eventScope: 'dates[form_date_to]',
                    eventValue: format(endDate, 'dd.MM.yyyy'),
                })

                AnalyticsService.trackEvent({
                    action: 'searchbox_add_departure',
                    category: 'searchbox',
                    label: format(endDate, 'yyyy-MM-dd'),
                })

                if (startDate) {
                    AnalyticsService.trackEvent({ eventAction: 'entered_arrival_and_departure', eventLabel: 'tbd' })
                }
            }
        },
        [format],
    )

    const _handleDepartureDateChange = useCallback(
        (date: Date | null) => {
            setDepartureDate(date)
            // We use `date` here instead of `departureDate` as it might not have been set yet by the `setDepartureDate`
            // call.
            if (arrivalDate && date) {
                onDatePickerClose()
            }
        },
        [arrivalDate, onDatePickerClose],
    )

    const _handleSubmit = useCallback(() => {
        if (location.search.length === 0) {
            setLocationPickerError(t('locationPickerError'))
            return
        }

        // Close all modals.
        onAllInputsModalClose()
        onLocationPickerClose()
        onDatePickerClose()
        _handleGuestPickerClose()

        // Submit.
        onSearch({ location, arrivalDate, departureDate, adultsCount, childrenCount })
    }, [
        location,
        onAllInputsModalClose,
        onLocationPickerClose,
        onDatePickerClose,
        _handleGuestPickerClose,
        onSearch,
        arrivalDate,
        departureDate,
        adultsCount,
        childrenCount,
        t,
    ])

    const datePickerType = useDatePickerType({ endDate: departureDate })

    const _locationPickerLabel = getLocationPickerLabel(location, t('whereTo'))

    const _datePickerLabel = useMemo(
        () =>
            getDatePickerLabel({
                startDate: arrivalDate,
                endDate: departureDate,
                startDatePlaceholder: t('checkIn'),
                endDatePlaceholder: t('checkOut'),
                datePickerType,
            }),
        [arrivalDate, departureDate, t, datePickerType],
    )

    const _guestPickerLabel = getGuestPickerLabel(_totalGuestsCount, t('guest'), t('guests'), t('addGuests'))

    return (
        <SearchbarContainer>
            <SearchbarContent maxWidth={maxWidth}>
                {isMobile ? (
                    <>
                        {!!isAllInputOpen && (
                            <AllInputsDisplayBar onClick={onAllInputsModalOpen}>
                                {_locationPickerLabel} &middot; {_datePickerLabel} &middot; {_guestPickerLabel}
                            </AllInputsDisplayBar>
                        )}
                        <AllInputsModal
                            isOpen={!!isAllInputOpen ? isAllInputOpen : isAllInputsModalOpen}
                            onLocationPickerOpen={_handleLocationPickerOpen}
                            onDatePickerOpen={onDatePickerOpen}
                            onGuestPickerOpen={_handleGuestPickerOpen}
                            locationPickerLabel={_locationPickerLabel}
                            datePickerLabel={_datePickerLabel}
                            guestPickerLabel={_guestPickerLabel}
                            locationPickerError={locationPickerError}
                            onClose={_handleAllInputsModalClose}
                            onSubmit={_handleSubmit}
                        />
                        <RegionsAutocompleteSuggestionPicker
                            isOpen={isLocationPickerOpen}
                            onOpen={_handleLocationPickerOpen}
                            onClose={onLocationPickerClose}
                            locationInput={location.search}
                            onLocationInputChange={_handleLocationInputChange}
                            onLocationInputSelect={_handleLocationInputSelect}
                            onLocationItemSelect={_handleLocationItemSelect}
                            onHistoryItemSelect={_handleHistoryItemSelect}
                            error={locationPickerError}
                        />
                        <SearchbarDatePicker
                            isOpen={isDatePickerOpen}
                            onOpen={onDatePickerOpen}
                            onClose={onDatePickerClose}
                            startDate={arrivalDate}
                            endDate={departureDate}
                            onStartDateChange={setArrivalDate}
                            onEndDateChange={_handleDepartureDateChange}
                            trackStartDateChange={_trackArrivalDateChange}
                            trackEndDateChange={_trackDepartureDateChange}
                        />
                        <SearchbarGuestPicker
                            isOpen={isGuestPickerOpen}
                            onOpen={_handleGuestPickerOpen}
                            onClose={_handleGuestPickerClose}
                            adultsCount={adultsCount}
                            childrenCount={childrenCount}
                            onAdultsCountChange={setAdultsCount}
                            onChildrenCountChange={setChildrenCount}
                        />
                    </>
                ) : (
                    <>
                        <RegionsAutocompleteSuggestionPicker
                            isOpen={isLocationPickerOpen}
                            onOpen={_handleLocationPickerOpen}
                            onClose={onLocationPickerClose}
                            locationInput={location.search}
                            onLocationInputChange={_handleLocationInputChange}
                            onLocationInputSelect={_handleLocationInputSelect}
                            onLocationItemSelect={_handleLocationItemSelect}
                            onHistoryItemSelect={_handleHistoryItemSelect}
                            error={locationPickerError}
                        />
                        <SearchbarDatePicker
                            isOpen={isDatePickerOpen}
                            onOpen={onDatePickerOpen}
                            onClose={onDatePickerClose}
                            startDate={arrivalDate}
                            endDate={departureDate}
                            onStartDateChange={setArrivalDate}
                            onEndDateChange={_handleDepartureDateChange}
                            trackStartDateChange={_trackArrivalDateChange}
                            trackEndDateChange={_trackDepartureDateChange}
                        />
                        <SearchbarGuestPicker
                            isOpen={isGuestPickerOpen}
                            onOpen={_handleGuestPickerOpen}
                            onClose={_handleGuestPickerClose}
                            adultsCount={adultsCount}
                            childrenCount={childrenCount}
                            onAdultsCountChange={setAdultsCount}
                            onChildrenCountChange={setChildrenCount}
                        />
                        <SearchButton
                            padding={isTablet ? `${theme.spacing.xSmall} ${theme.spacing.medium}` : undefined}
                            onClick={_handleSubmit}
                        >
                            {isTablet ? <SearchIcon alt={t('search')} /> : t('search')}
                        </SearchButton>
                    </>
                )}
            </SearchbarContent>
        </SearchbarContainer>
    )
}

export default Searchbar
