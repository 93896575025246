import React, { FC, memo, Ref } from 'react'

import { GuestPickerIcon, NonSelectableWidgetInputContainer, WidgetContainer } from '../widget-styles'
import { useTranslation } from '../../../../../hooks/locale'
import { GuestsInput, GuestPickerWidgetCard } from './widget-styles'
import { getGuestPickerLabel } from '../utils'
import { GuestPickerWidgetProps } from '../../../../common/guest-picker/types'
import { InputError } from '../../../../common/input-styles'

const GuestPickerWidget: FC<GuestPickerWidgetProps> = ({
    isOpen,
    onOpen,
    adultsCount,
    childrenCount,
    error,
    inputRef,
    children,
}) => {
    const { t } = useTranslation()

    const _totalGuestsCount = adultsCount + childrenCount
    const _guestPickerLabel = getGuestPickerLabel(_totalGuestsCount, t('guest'), t('guests'), t('addGuests'))

    return (
        <WidgetContainer ref={inputRef as Ref<HTMLDivElement>}>
            <NonSelectableWidgetInputContainer hasError={!!error}>
                <GuestPickerIcon alt={t('guestIconText')} />
                <GuestsInput value={_guestPickerLabel} onClick={onOpen} />
                {!!error && <InputError alignRight>{error}</InputError>}
            </NonSelectableWidgetInputContainer>

            <GuestPickerWidgetCard isOpen={isOpen}>{children}</GuestPickerWidgetCard>
        </WidgetContainer>
    )
}

export default memo(GuestPickerWidget)
