import React, { FC } from 'react'

import { useOnClickOutside } from '../../../hooks/dom'
import GuestCountersContainer from './guest-counters-container'
import { GuestPickerExclusiveProps, GuestPickerPassThroughProps } from './types'

type Props = GuestPickerPassThroughProps & {
    component: GuestPickerExclusiveProps['widgetComponent']
}

const Widget: FC<Props> = ({ component: Component, isOpen, onOpen, onClose, ...restProps }) => {
    const { ref: _widgetInputContainerRef } = useOnClickOutside(onClose)

    return (
        <Component {...restProps} isOpen={isOpen} onOpen={onOpen} onClose={onClose} inputRef={_widgetInputContainerRef}>
            <GuestCountersContainer {...restProps} />
        </Component>
    )
}

export default Widget
