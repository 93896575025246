import React, { FC, KeyboardEvent, memo, MouseEvent, MouseEventHandler, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import {
    AllInputsModalContainer,
    Col,
    DatePickerIcon,
    GuestPickerIcon,
    Input,
    InputsContainer,
    LocationPickerIcon,
    Row,
} from './styles'
import { useTranslation } from '../../../../../hooks/locale'
import ModalHeader from '../../../../common/modal/modal-header'
import ModalFooter from '../../../../common/modal/modal-footer'
import { SubmitButton } from '../styles'
import { InputError } from '../../../../common/input-styles'

interface Props {
    isOpen: boolean
    onLocationPickerOpen: () => void
    onDatePickerOpen: () => void
    onGuestPickerOpen: () => void
    locationPickerLabel: string
    datePickerLabel: string
    guestPickerLabel: string
    locationPickerError?: string
    onClose: (event: MouseEvent | KeyboardEvent) => void
    onSubmit: MouseEventHandler<HTMLButtonElement>
}

const AllInputsModal: FC<Props> = ({
    isOpen,
    onLocationPickerOpen,
    onDatePickerOpen,
    onGuestPickerOpen,
    locationPickerLabel,
    datePickerLabel,
    guestPickerLabel,
    locationPickerError,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)

    return (
        <AllInputsModalContainer isOpen={isOpen} onRequestClose={onClose}>
            <ModalHeader title={t('allInputsHeading')} onClose={onClose} />
            <InputsContainer>
                <Row>
                    <Col hasError={!!locationPickerError} onClick={onLocationPickerOpen} role="button">
                        <LocationPickerIcon alt={t('mapIconText')} />
                        <Input value={locationPickerLabel} />
                        {!!locationPickerError && <InputError>{locationPickerError}</InputError>}
                    </Col>
                </Row>
                <Row>
                    <Col onClick={onDatePickerOpen} role="button">
                        <DatePickerIcon alt={t('calendarIconText')} />
                        <Input value={datePickerLabel} />
                    </Col>
                    <Col onClick={onGuestPickerOpen} role="button">
                        <GuestPickerIcon alt={t('guestIconText')} />
                        <Input value={guestPickerLabel} />
                    </Col>
                </Row>
            </InputsContainer>
            <ModalFooter padding={theme.spacing.medium}>
                <SubmitButton onClick={onSubmit}>{t('searchButtonText')}</SubmitButton>
            </ModalFooter>
        </AllInputsModalContainer>
    )
}

export default memo(AllInputsModal)
