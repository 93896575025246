import React, { FC, memo, useCallback } from 'react'

import { useTranslation } from '../../../../../hooks/locale'
import { GuestPickerModalContainer } from './modal-styles'
import {
    FullScreenModalBody,
    FullScreenModalFooter,
    FullScreenModalHeader,
} from '../../../../common/modal/full-screen-modal'
import { SubmitButton } from '../styles'
import { GuestPickerModalProps } from '../../../../common/guest-picker/types'
import { InputError } from '../../../../common/input-styles'
import { NonSelectableWidgetInputContainer, GuestPickerIcon } from '../widget-styles'
import { GuestsInput } from './widget-styles'
import { getGuestPickerLabel } from '../utils'

const GuestPickerModal: FC<GuestPickerModalProps> = ({
    isOpen,
    onClose,
    onReset,
    onClear,
    onSubmit,
    children,
    onOpen,
    adultsCount,
    childrenCount,
    error,
    showInputInModal = false,
}) => {
    const { t } = useTranslation()

    const _handleReset = useCallback(() => {
        onReset()
        onClose()
    }, [onClose, onReset])

    const _handleSubmit = useCallback(() => {
        onSubmit()
        onClose()
    }, [onClose, onSubmit])

    const _totalGuestsCount = adultsCount + childrenCount
    const _guestPickerLabel = getGuestPickerLabel(_totalGuestsCount, t('guest'), t('guests'), t('addGuests'))

    return (
        <React.Fragment>
            {showInputInModal && (
                <NonSelectableWidgetInputContainer hasError={!!error}>
                    <GuestPickerIcon alt={t('guestIconText')} />
                    <GuestsInput value={_guestPickerLabel} onClick={onOpen} />
                    {!!error && <InputError alignRight>{error}</InputError>}
                </NonSelectableWidgetInputContainer>
            )}
            <GuestPickerModalContainer isOpen={isOpen} onRequestClose={_handleReset}>
                <FullScreenModalHeader
                    title={t('guestModalTitle')}
                    onClose={_handleReset}
                    onClear={onClear}
                    hasBorderBottom
                />

                <FullScreenModalBody>{children}</FullScreenModalBody>

                <FullScreenModalFooter hasBorderTop>
                    <SubmitButton onClick={_handleSubmit}>{t('select')}</SubmitButton>
                </FullScreenModalFooter>
            </GuestPickerModalContainer>
        </React.Fragment>
    )
}

export default memo(GuestPickerModal)
