import styled, { css, StyledComponent } from 'styled-components'

import { DefaultTheme } from '../../../styled-components'

import MinusSquareBox from '../../../assets/icons/userbar-icons/minus-square-box.svg'
import PlusSquareBox from '../../../assets/icons/userbar-icons/plus-square-box.svg'

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: ${theme.layout.fill};
        border-bottom: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        padding: ${theme.spacing.medium} 0;

        &:last-child {
            border-bottom: none;
        }
    `,
)

export const CounterTitle = styled.span(
    ({ theme }) => css`
        display: block;
        font-size: ${theme.typography.font.size.small};
        line-height: ${theme.typography.font.size.xLarge};
    `,
)

export const CounterDescription = styled(CounterTitle)(
    ({ theme }) => css`
        font-size: 0.8125rem;
        line-height: ${theme.typography.font.size.medium};
        color: ${theme.colors.tfwBlackOpacity50};
        letter-spacing: 0.0125rem;
    `,
)

export const CounterButtonsContainer = styled.div`
    display: flex;
    align-items: center;

    /* Give min-width here, to avoid flickering of button positions when count value becomes double digit. */
    min-width: 8.125rem;
`

export const CounterButton = styled.button.attrs({ type: 'button' })(
    ({ theme }) => css`
        height: ${theme.layout.small};
        width: ${theme.layout.small};
        border-radius: ${theme.typography.border.radius.xSmall};
        user-select: none;
        background-color: ${theme.colors.white};
        transition: background-color 200ms ease-out;

        &:hover {
            background-color: ${theme.colors.tfwBlack87};
        }

        &:disabled,
        &:hover:disabled {
            background-color: ${theme.colors.white};
            opacity: 0.25;
            cursor: not-allowed;
        }
    `,
)

export const CounterValue = styled(CounterTitle)`
    margin: 0 auto;
    user-select: none;
`

export const CounterButtonIcon = styled.svg(
    ({ theme }) => css`
        path {
            stroke: ${theme.colors.tfwBlack87};
            transition: stroke 200ms ease-out;
        }
        rect {
            stroke: ${theme.colors.tfwBlack12};
            stroke-opacity: 1;
            transition: stroke 200ms ease-out;
        }

        ${CounterButton}:hover & {
            path {
                stroke: ${theme.colors.white};
            }
            rect {
                stroke: ${theme.colors.tfwBlack87};
            }
        }

        ${CounterButton}:disabled &,
        ${CounterButton}:hover:disabled & {
            path {
                stroke: ${theme.colors.tfwBlack87};
            }
            rect {
                stroke: ${theme.colors.tfwBlack12};
            }
        }
    `,
)

export const MinusIcon = styled(CounterButtonIcon as StyledComponent<'img', DefaultTheme>).attrs({
    as: MinusSquareBox,
})``

export const PlusIcon = styled(CounterButtonIcon as StyledComponent<'img', DefaultTheme>).attrs({ as: PlusSquareBox })``
