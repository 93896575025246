import React, { PropsWithChildren, useCallback } from 'react'

import {
    Container,
    CounterButton,
    CounterButtonsContainer,
    CounterDescription,
    CounterTitle,
    CounterValue,
    MinusIcon,
    PlusIcon,
} from './styles'
import { useTranslation } from '../../../hooks/locale'

export interface CounterProps<T> {
    title: string
    type: T
    description?: string
    value: number
    isIncrementActive?: boolean
    isDecrementActive?: boolean
    incrementCounter: (type: T) => void
    decrementCounter: (type: T) => void
}

const Counter = <T extends string | number>({
    title,
    type,
    description,
    value,
    isIncrementActive = true,
    isDecrementActive = true,
    incrementCounter,
    decrementCounter,
}: PropsWithChildren<CounterProps<T>>) => {
    const { t } = useTranslation()

    const handleDecrement = useCallback(() => decrementCounter(type), [decrementCounter, type])
    const handleIncrement = useCallback(() => incrementCounter(type), [incrementCounter, type])

    return (
        <Container>
            <div>
                <CounterTitle>{title}</CounterTitle>
                {!!description && <CounterDescription>{description}</CounterDescription>}
            </div>
            <CounterButtonsContainer>
                <CounterButton onClick={handleDecrement} disabled={!isDecrementActive}>
                    <MinusIcon alt={t('minusIcon')} />
                </CounterButton>
                <CounterValue>{value >= 0 ? value : 0}</CounterValue>
                <CounterButton onClick={handleIncrement} disabled={!isIncrementActive}>
                    <PlusIcon alt={t('plusIcon')} />
                </CounterButton>
            </CounterButtonsContainer>
        </Container>
    )
}

export default Counter
