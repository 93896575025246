import { useCallback } from 'react'

import { GuestType } from '../../search-result-listing/header-section/types'
import { MAX_ADULTS, MAX_CHILDREN, MAX_TOTAL_GUESTS, MIN_ADULTS, MIN_CHILDREN } from './constants'
import { GuestCountersProps } from './types'

export const useGuestCounters = ({
    adultsCount,
    childrenCount,
    onAdultsCountChange,
    onChildrenCountChange,
    minAdults = MIN_ADULTS,
    maxAdults = MAX_ADULTS,
    minChildren = MIN_CHILDREN,
    maxChildren = MAX_CHILDREN,
    maxTotalGuests = MAX_TOTAL_GUESTS,
}: GuestCountersProps) => {
    const isAdultsIncrementCounterActive = adultsCount < maxAdults && adultsCount + childrenCount < maxTotalGuests
    const isAdultsDecrementCounterActive = adultsCount > minAdults
    const isChildrenIncrementCounterActive = childrenCount < maxChildren && adultsCount + childrenCount < maxTotalGuests
    const isChildrenDecrementCounterActive = childrenCount > minChildren

    const _incrementCounter = useCallback(
        (type: GuestType) => {
            switch (type) {
                case GuestType.GUEST_TYPE_ADULT:
                    onAdultsCountChange(adultsCount < maxAdults ? adultsCount + 1 : adultsCount)
                    break
                case GuestType.GUEST_TYPE_CHILDREN:
                    onChildrenCountChange(childrenCount < maxChildren ? childrenCount + 1 : childrenCount)
                    break
                default:
                // unknown type
            }
        },
        [adultsCount, childrenCount, maxAdults, maxChildren, onAdultsCountChange, onChildrenCountChange],
    )

    const _decrementCounter = useCallback(
        (type: GuestType) => {
            switch (type) {
                case GuestType.GUEST_TYPE_ADULT:
                    onAdultsCountChange(adultsCount > minAdults ? adultsCount - 1 : adultsCount)
                    break
                case GuestType.GUEST_TYPE_CHILDREN:
                    onChildrenCountChange(childrenCount > minChildren ? childrenCount - 1 : childrenCount)
                    break
                default:
                // unknown type
            }
        },
        [adultsCount, childrenCount, minAdults, minChildren, onAdultsCountChange, onChildrenCountChange],
    )

    return {
        incrementCounter: _incrementCounter,
        decrementCounter: _decrementCounter,
        isAdultsIncrementCounterActive,
        isAdultsDecrementCounterActive,
        isChildrenIncrementCounterActive,
        isChildrenDecrementCounterActive,
    }
}
