import React, { FC, memo } from 'react'

import GuestCounter from './guest-counter'
import { GuestType } from '../../search-result-listing/header-section/types'
import { useTranslation } from '../../../hooks/locale'
import { GuestsContainer, MaximumGuestWrapper } from './guest-counters-container-styles'
import { useGuestCounters } from './hooks'
import { GuestCountersProps } from './types'
import { Typography } from 'react-components'

const GuestCountersContainer: FC<GuestCountersProps> = ({
    adultsCount,
    childrenCount,
    onAdultsCountChange,
    onChildrenCountChange,
    minAdults,
    maxAdults,
    minChildren,
    maxChildren,
    maxTotalGuests,
}) => {
    const { t } = useTranslation()

    const {
        incrementCounter,
        decrementCounter,
        isAdultsIncrementCounterActive,
        isAdultsDecrementCounterActive,
        isChildrenIncrementCounterActive,
        isChildrenDecrementCounterActive,
    } = useGuestCounters({
        adultsCount,
        childrenCount,
        onAdultsCountChange,
        onChildrenCountChange,
        minAdults,
        maxAdults,
        minChildren,
        maxChildren,
        maxTotalGuests,
    })

    return (
        <GuestsContainer>
            <GuestCounter
                title={t(GuestType.GUEST_TYPE_ADULT)}
                type={GuestType.GUEST_TYPE_ADULT}
                value={adultsCount}
                isIncrementActive={isAdultsIncrementCounterActive}
                isDecrementActive={isAdultsDecrementCounterActive}
                incrementCounter={incrementCounter}
                decrementCounter={decrementCounter}
            />
            <GuestCounter
                title={t(GuestType.GUEST_TYPE_CHILDREN)}
                type={GuestType.GUEST_TYPE_CHILDREN}
                description={t('childrenSubLabel')}
                value={childrenCount}
                isIncrementActive={isChildrenIncrementCounterActive}
                isDecrementActive={isChildrenDecrementCounterActive}
                incrementCounter={incrementCounter}
                decrementCounter={decrementCounter}
            />
            {!!maxTotalGuests && (
                <MaximumGuestWrapper>
                    <Typography variant="caption" color="tfwBlackOpacity50" fontWeight="regular">
                        {t('price.maximumNumberOfGuestsExceeded', {
                            maxTotalGuests,
                        })}
                    </Typography>
                </MaximumGuestWrapper>
            )}
        </GuestsContainer>
    )
}

export default memo(GuestCountersContainer)
