import styled from 'styled-components'

import { SearchbarWidgetCard, WidgetInput } from '../widget-styles'

export const GuestsInput = styled(WidgetInput).attrs({ readOnly: true, 'aria-readonly': false })`
    user-select: none;
    cursor: pointer;
`

export const GuestPickerWidgetCard = styled(SearchbarWidgetCard)`
    min-width: 21.5rem; /* 344px. */
    max-width: 23.5rem; /* 344px. */
    right: -0.0625rem; /* -1px. */
`
