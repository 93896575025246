import React, { FC } from 'react'

import { GuestPickerMode, GuestPickerProps } from './types'
import Modal from './modal'
import Widget from './widget'

const GuestPicker: FC<GuestPickerProps> = ({ mode, modalComponent, widgetComponent, ...restProps }) => {
    if (mode === GuestPickerMode.MODAL) {
        return <Modal {...restProps} component={modalComponent} />
    }

    return <Widget {...restProps} component={widgetComponent} />
}

export default GuestPicker
