import React, { FC, useContext } from 'react'
import { ScreenContext } from 'react-components'

import GuestPicker from '../../../../common/guest-picker'
import { GuestPickerExclusiveProps, GuestPickerMode, GuestPickerProps } from '../../../../common/guest-picker/types'
import GuestPickerModal from './modal'
import GuestPickerWidget from './widget'

type Props = Omit<GuestPickerProps, keyof GuestPickerExclusiveProps>

const SearchbarGuestPicker: FC<Props> = props => {
    const { isMobile } = useContext(ScreenContext)

    return (
        <GuestPicker
            mode={isMobile ? GuestPickerMode.MODAL : GuestPickerMode.WIDGET}
            modalComponent={GuestPickerModal}
            widgetComponent={GuestPickerWidget}
            {...props}
        />
    )
}

export default SearchbarGuestPicker
