import React, { FC, useContext } from 'react'
import { ScreenContext } from 'react-components'

import { DatePickerExclusiveProps, DatePickerMode, DatePickerProps } from '../../../../common/date-picker/types'
import DatePickerModal from './modal'
import DatePickerWidget from './widget'
import DatePicker from '../../../../common/date-picker'
import { DatePickerModalCalendarContainer } from './modal-styles'

type Props = Omit<DatePickerProps, keyof DatePickerExclusiveProps>

const SearchbarDatePicker: FC<Props> = props => {
    const { isMobile } = useContext(ScreenContext)

    return (
        <DatePicker
            mode={isMobile ? DatePickerMode.MODAL : DatePickerMode.WIDGET}
            modalComponent={DatePickerModal}
            widgetComponent={DatePickerWidget}
            modalCalendarContainerComponent={DatePickerModalCalendarContainer}
            {...props}
        />
    )
}

export default SearchbarDatePicker
