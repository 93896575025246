import styled, { AnyStyledComponent, css } from 'styled-components'

import Modal from '../../../../common/modal'

import { CalendarIcon, GuestsIcon, LocationPinIcon } from '../../../../common/icons'

export const AllInputsModalContainer = styled(Modal)(
    ({ theme }) => css`
        user-select: none;

        & .Overlay {
            align-items: flex-start;
        }

        & .Modal {
            width: ${theme.layout.fill};
            border-radius: 0;
        }
    `,
)

export const InputsContainer = styled.div(
    ({ theme }) => css`
        margin: 0 ${theme.spacing.medium};
    `,
)

export const Row = styled.div(
    ({ theme }) => css`
        display: flex;

        &:first-child > :first-child {
            border-top-left-radius: ${theme.typography.border.radius.xLarge};
        }

        &:first-child > :last-child {
            border-top-right-radius: ${theme.typography.border.radius.xLarge};
        }

        &:last-child > :first-child {
            border-bottom-left-radius: ${theme.typography.border.radius.xLarge};
        }

        &:last-child > :last-child {
            border-bottom-right-radius: ${theme.typography.border.radius.xLarge};
        }
    `,
)

interface InputProps {
    hasError?: boolean
}

export const Col = styled.div<InputProps>(
    ({ theme, hasError }) => css`
        display: flex;
        align-items: center;
        flex: 1 1 0;
        height: ${theme.layout.fill};
        border: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        position: relative;
        cursor: pointer;

        ${!!hasError &&
        css`
            border-width: ${theme.typography.border.width.xxThin};
            border-color: ${theme.colors.tfwErrorRed};

            & * {
                color: ${theme.colors.tfwErrorRed};
                stroke: ${theme.colors.tfwErrorRed};
            }
        `};
    `,
)

export const Input = styled.input.attrs({ readOnly: true, 'aria-readonly': false })(
    ({ theme }) => css`
        width: ${theme.layout.fill};
        background-color: transparent;
        font-size: ${theme.typography.font.size.small};
        line-height: ${theme.typography.font.size.xLarge};
        padding: ${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.xxLarge};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
    `,
)

const InputIcon = styled.svg(
    ({ theme }) => css`
        position: absolute;
        left: ${theme.spacing.medium};
    `,
)

export const LocationPickerIcon = styled(InputIcon as AnyStyledComponent).attrs({ as: LocationPinIcon })``
export const DatePickerIcon = styled(InputIcon as AnyStyledComponent).attrs({ as: CalendarIcon })``
export const GuestPickerIcon = styled(InputIcon as AnyStyledComponent).attrs({ as: GuestsIcon })``
