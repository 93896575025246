import { ComponentType, Ref } from 'react'

interface GuestCountersLimits {
    minAdults: number
    maxAdults: number
    minChildren: number
    maxChildren: number
    maxTotalGuests: number
}

export interface GuestCountersProps extends Partial<GuestCountersLimits> {
    adultsCount: number
    childrenCount: number
    onAdultsCountChange: (count: number) => void
    onChildrenCountChange: (count: number) => void
}

export enum GuestPickerMode {
    MODAL = 'modal',
    WIDGET = 'widget',
}

export interface GuestPickerPassThroughProps extends GuestCountersProps {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    error?: string
    showInputInModal?: boolean
}

export interface GuestPickerModalProps extends GuestPickerPassThroughProps {
    onReset: () => void
    onClear: () => void
    onSubmit: () => void
}

export interface GuestPickerWidgetProps extends GuestPickerPassThroughProps {
    inputRef: Ref<HTMLElement>
}

export interface GuestPickerExclusiveProps {
    mode: GuestPickerMode
    modalComponent: ComponentType<GuestPickerModalProps>
    widgetComponent: ComponentType<GuestPickerWidgetProps>
}

export type GuestPickerProps = GuestPickerPassThroughProps & GuestPickerExclusiveProps
